
export const scheme = process.env.REACT_APP_URL_SCHEME ?? 'http://'
export const ThumbCDNUrl = process.env.REACT_APP_THUMB_CDN_URL ?? 'gpazjnfhanuq13159679.cdn.ntruss.com';

export function makeResourceUrl(it) {
    return `${scheme}${it.cdnUrl}${it.resourcePath}${it.resourceFile}`
}

/**
 * @param {object} it 썸네일 URL을 만들고 싶은 데이터
 * @return {string}
 */
export function makeThumbnailUrl(it) {
    return makePath(
        scheme,
        it?.thumb_cdnUrl ?? it?.cdnUrl ?? ThumbCDNUrl,
        it?.thumb_path ?? it?.thumbPath ??  it?.org_path ?? it?.orgPath,
        it?.thumb_file ?? it?.thumbFile,
    );
}

export function makeImageOrgUrl(it) {
    return makePath(
        scheme,
        it?.file?.org_cdnUrl,
        it?.file?.orgPath,
        it?.file?.orgFile,
    );
}

/**
 * @param {object} it 첨부파일 URL을 만들고 싶은 데이터
 * @return {string}
 */
export function makeAttachUrl(it) {
    return makePath(
        scheme,
        it?.curlUrl_Attach,
        it?.file_path,
        it?.file_name + '?token=' + it?.tokenUrl
    );
}


/**
 * @param {VideoContentItem} it 썸네일 URL을 만들고 싶은 데이터
 * @return {string}
 */
export function makeVideoThumbnailUrl(it) {
    const cdnUrl = it.videocontent.thumb_cdnUrl ?? ThumbCDNUrl;
    // 사용자 업로드 썸네일의 경우 userThumb 경로를 포함하고 있음.
    if(it.videocontent?.thumb_path?.includes('userThumb') === true){
        return makePath(
            scheme,
            cdnUrl,
            it?.videocontent?.thumb_path ?? it?.thumbPath ?? it.file?.[0]?.proxyPath ?? it?.org_path ?? it?.orgPath,
            it?.videocontent?.thumb_file ?? it?.thumbFile ?? it.file?.[0]?.thumbFiles,
        )
    }
    else {
        return makePath(
            scheme,
            cdnUrl,
            it?.thumb_path ?? it?.thumbPath ?? it.file?.[0]?.proxyPath ?? it?.org_path ?? it?.orgPath,
            it.file?.[0]?.fileId,
            'thumb',
            it?.thumb_file ?? it?.thumbFile ?? it.file?.[0]?.thumbFiles,
        );
    }
}

export function makeImagePosterUrl(it) {
    return makePath(scheme, it?.thumb_cdnUrl, it?.orgPath, it?.posterFile);
}

export function makeLivePoster(it) {
    if(it == null || it?.thumb_cdnUrl == null || it?.thumbPath == null || it?.posterFile == null) return null;
    else return makePath(scheme, it?.thumb_cdnUrl, it?.thumbPath, it?.posterFile);
}

export function makePath(scheme, ...list) {
    return `${scheme}${list.join('/').replaceAll(/\/+/g, '/')}`
}